<template>
  <list-template
      :current-page="page"
      :loading="loading"
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      has-add
      @onAdd="$router.push('./add?id=1')"
      @onChangePage="handleCurrentChange"
      @onExportData="exportData"
      @onHandle="handleEdit"
      @onReset="onReset"
      @onSearch="onSearch"
      @onChange="onChange"
  ></list-template>
</template>

<script>
import {mapState} from "vuex";
import {deepCopy} from "@/utils/l-tools";
import tools from "@/utils/tools";
import { getConfigValue, setConfigValue } from '@/utils'

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  props: {
    is_sign: {
      type: Array,
      default: []
    },
    sign_status: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: "student_no",
          placeholder: "搜索学号"
        },
        {
          prop: "school_id",
          tag: 'select',
          placeholder: "筛选就读校区",
          label: 'school_name',
          value: 'id',
          options: [],
          change: (val, search) => {
            this.getClass(search)
          }
        },
        {
          prop: "grade_id",
          tag: 'select',
          placeholder: "筛选原始年级",
          label: 'name',
          value: 'id',
          options: [],
        },
        {
          prop: "stay_down_grade_id",
          tag: 'select',
          placeholder: "筛选留级后转入年级",
          label: 'name',
          value: 'id',
          options: [],
          change: (val, search) => {
            this.getClass(search)
          }
        },
        {
          prop: "stay_down_class_id",
          tag: 'select',
          placeholder: "筛选留级后转入行政班",
          label: 'class_name',
          value: 'class_id',
          options: [],
        },
        {
          prop: "is_sign",
          tag: 'select',
          placeholder: "筛选是否需签署协议",
          label: 'name',
          value: 'id',
          options: [],
        },
        {
          prop: "sign_status",
          tag: 'select',
          placeholder: "筛选签署状态",
          label: 'name',
          value: 'id',
          options: [],
        },
      ],
      tableData: [],
      total: 0,
      tableConfig: [
        {"prop": "student_name", "label": "学生姓名"},
        {"prop": "student_no", "label": "学号"},
        {"prop": "school_name", "label": "就读校区"},
        {"prop": "grade_name", "label": "原始年级"},
        {"prop": "stay_down_grade", "label": "留级后转入年级"},
        {"prop": "stay_down_class", "label": "留级后转入行政班"},
        {"prop": "is_sign", "label": "是否需签署协议"},
        {"prop": "sign_status", "label": "签署状态"},
        {"prop": "handle", label: "操作", width: "180rem", handle: true,},
      ],
    }
  },
  watch: {
    is_sign: {
      handler(val) {
        this.searchConfig[6].options = val
      },
      deep: true
    },
    sign_status: {
      handler(val) {
        this.searchConfig[7].options = val
      },
      deep: true
    }
  },
  created() {
    this.searchConfig[6].options = this.is_sign
    this.searchConfig[7].options = this.sign_status
    this.$store.commit("setPage", 1);
    this.$_register.get('api/recruit/common/consulting-school').then(res => {
      let data = res.data.data.entity_school;
      if (data.length === 0) return this.searchConfig[2].options = []
      data.unshift({
        id: "0",
        school_name: "全部"
      })
      this.searchConfig[2].options = data
    })
    this.$_axios.get("/site/grades").then(res => {
      let {data} = res.data;
      if (data.length === 0) return this.searchConfig[3].options = []
      data.unshift({
        id: "0",
        name: "全部"
      })
      this.searchConfig[3].options = data
      this.searchConfig[4].options = data
    })
    this.init()
  },
  activated() {
    this.init()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    init() {
      const name = this.$route.query.student_name
      if (name) {
        this.$store.commit("setPage", 1);
        setConfigValue({ list: this.searchConfig, key: 'student_name', changeKey: 'default', value: name })
        this.search.student_name = name
      }
      this.getData()
    },
    onChange(val) { this.search = val },
    onReset() {
      this.searchConfig[5].options = []
      if (getConfigValue({list: this.searchConfig, key: 'student_name', valueKey: 'default'})) {
        setConfigValue({ list: this.searchConfig, key: 'student_name', changeKey: 'default' })
        this.$router.replace('./list')
      }
      this.search = {}
    },
    getData(val = {}) {
      this.loading = true;
      let params = {...this.search, page: this.page, ...val}
      if (!val.type) this.tableData = [];
      this.$_axios2('api/student/student-adjust/stay-down', {params}).then(res => {
        if (!!params.type) return tools.download('留级名单', res.data)
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false;
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, handle) {
      if (handle.type === 'view') {
        this.$router.push('./look?id=' + row.id + '&type=1')
      }
    },

    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getData()
    },

    exportData() {
      this.getData({type: 1})
    },

    getClass(val) {
      if (val?.school_id && val['stay_down_grade_id']) {
        let params = deepCopy(val)
        params.grade_id = params['stay_down_grade_id']
        delete params['stay_down_grade_id']
        this.$_axios.get("/site/role-admin-class", {params}).then(res => {
          let {data} = res.data;
          if (data.length === 0) return this.searchConfig[5].options = data
          data.unshift({
            class_id: "0",
            class_name: "全部"
          })
          this.searchConfig[5].options = data
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
